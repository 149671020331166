<template>
	<div class="vote-table">
		<v-data-table
			class="overflow-x elevation-1"
			height="100%"
			item-key="name"
			hide-default-footer
			:headers="headers"
			:items="items"
			:loading="loading"
			:page.sync="options.page"
			:options="options"
			:server-items-length="options.itemsLength"
			@click:row="handleRowClick"
		>
			<!--  ХЭДЕР ТАБЛИЦЫ -->
			<template v-slot:top="{ pagination, options, updateOptions }">
				<v-data-footer
					class="justify-end pa-3 border-bottom"
					:pagination="pagination"
					:options="options"
					@update:options="updateOptions"
					items-per-page-text=""
					:items-per-page-options="[]"
					disable-items-per-page
					:disable-pagination="loading"
					:show-first-last-page="true"
				>
					<!-- <template v-slot:page-text>
						{{ options.page || "-" }} из {{ options.pageCount || "-" }}
					</template> -->
					<template v-slot:prepend>
						<div class="d-flex flex-sm-grow-1 align-center justify-start">
							<!-- Кнопка создания -->
							<v-btn
								class="mr-5"
								color="primary"
								@click="showModalCreate = true"
								small
								depressed
							>
								<v-icon class="mr-1" small>mdi-plus</v-icon>
								<span class="body-2 font-weight-light">Создать</span>
							</v-btn>
							<!-- Дата создания -->
							<div class="d-flex align-center mr-3">
								<span class="subtitle-2 grey--text mr-3">от</span>
								<DatePickerPlain
									:date.sync="filter.created_at_first_search"
									@change="updateQuery"
								/>
								<span class="mx-3 subtitle-2 grey--text">до</span>
								<DatePickerPlain
									:date.sync="filter.created_at_second_search"
									@change="updateQuery"
								/>
								<v-btn class="ml-3" icon>
									<v-icon>mdi-magnify</v-icon>
								</v-btn>
							</div>
							<!-- Сброс фильтров -->
							<v-btn
								class="mr-5 text-lowercase"
								color="primary"
								small
								outlined
								@click="resetFilters"
							>
								<span class="body-2 font-weight-light">
									<span class="text-capitalize">Сбросить</span> фильтры
								</span>
							</v-btn>
							<v-btn
								color="primary"
								small
								depressed
								:loading="isExportingExcel"
								@click="exportExcel"
							>
								<v-icon class="mr-1" small>mdi-microsoft-excel</v-icon>
								<span class="body-2 font-weight-light text-capitalize">
									Экспорт
								</span>
							</v-btn>
						</div>
					</template>
				</v-data-footer>
			</template>
			<!-- ФИЛЬТРЫ ТАБЛИЦЫ -->
			<!-- <template v-slot:[`body.prepend`]>
				<tr>
					<td>
						<v-text-field
							v-model="filter.id"
							class="border-all"
							solo
							dense
							flat
							hide-details
							@change="updateQuery"
						>
						</v-text-field>
					</td>
					<td>
						<v-autocomplete
							v-model="filter.house"
							class="border-all"
							solo
							dense
							flat
							hide-details
							clearable
							item-text="apartment_address"
							item-value="apartment_address"
							:items="houses"
							:filter="addressFilter"
							@change="updateQuery"
						>
						</v-autocomplete>
					</td>
					<td>
						<v-text-field
							v-model="filter.employee_executor"
							class="border-all"
							solo
							dense
							flat
							clearable
							hide-details
							@change="updateQuery"
						>
						</v-text-field>
					</td>
					<td>
						<v-select
							v-model="filter.publish"
							class="border-all"
							solo
							dense
							flat
							hide-details
							:items="[
								{ text: 'Все', value: '' },
								{ text: 'Опубликованные', value: '1' },
								{ text: 'Неопубликованные', value: '0' },
							]"
							@change="updateQuery"
						>
						</v-select>
					</td>
					<td>
						<v-text-field
							v-model="filter.apartment_number"
							class="border-all"
							solo
							dense
							flat
							hide-details
							@change="updateQuery"
						>
						</v-text-field>
					</td>
					<td>
						<DatePickerPlain
							:date.sync="filter.created_at"
							:in-table="true"
							@change="updateQuery"
						/>
					</td>
					<td>
						<DatePickerPlain
							:date.sync="filter.time_vote"
							:in-table="true"
							@change="updateQuery"
						/>
					</td>
				</tr>
			</template> -->
			<!-- ПОЛЯ ТАБЛИЦЫ -->
			<template slot="items">
				<!-- <tr></tr> -->
			</template>
			<template v-slot:[`item.id`]="{ item }">
				<td>
					<v-badge
						v-if="item.unread_comment"
						class="mr-2"
						color="red"
						dot
						overlap
						bordered
					>
						<v-icon color="primary" small>mdi-message-outline</v-icon>
					</v-badge>
					<span>{{ item.id }}</span>
				</td>
			</template>
			<template v-slot:[`item.house`]="{ item }">
				<td>
					{{ item.house.apartment_address }}
				</td>
			</template>
			<template v-slot:[`item.count_apartments`]="{ item }">
				<td>
					{{ countApartments(item) }}
				</td>
			</template>
			<template v-slot:[`item.phone`]="{ item }">
				<td>
					{{ item.phone || "---" }}
				</td>
			</template>
			<template v-slot:[`item.employee_period_of_execution`]="{ item }">
				<td>
					{{ item.employee_period_of_execution }}
				</td>
			</template>
			<template v-slot:[`item.questions`]="{ item }">
				<td>
					<v-tooltip bottom>
						<template v-slot:activator="{ on, attrs }">
							<td dark v-bind="attrs" v-on="on">
								{{ getQuestions(item) | textLimit }}
							</td>
						</template>
						<span>{{ getQuestions(item) }}</span>
					</v-tooltip>
				</td>
			</template>
			<template v-slot:[`item.created_at`]="{ item }">
				<td>
					{{ item.created_at | timestamp(true) }}
				</td>
			</template>
			<template v-slot:[`item.time_vote`]="{ item }">
				<td>
					{{ item.time_vote | timestamp(true) }}
				</td>
			</template>
			<template v-slot:[`item.publish`]="{ item }">
				<td>
					<span v-if="item.publish" class="primary--text">Опубликовано</span>
					<span v-else class="red--text">Неопубликовано</span>
				</td>
			</template>
		</v-data-table>
		<!-- ОКНО СОЗДАНИЯ ЗАЯВКИ -->
		<VoteTableModal
			v-if="showModalCreate"
			@update-info="updateQuery"
			@close-modal="showModalCreate = false"
		/>
	</div>
</template>

<script>
import { timestampToDdmmyyyy } from "@/helpers/helpers";
import { mapGetters } from "vuex";

import DatePickerPlain from "@/components/app/DatePickerPlain";
import VoteTableModal from "@/components/VoteTableModal";

export default {
	name: "RequisitionsTable",
	components: {
		DatePickerPlain,
		VoteTableModal,
	},
	watch: {
		options: {
			handler() {
				const query = { ...this.$route.query, page: this.options.page };
				this.$router.replace({ query });
				this.loadData(query);
			},
			deep: true,
		},
	},
	data() {
		return {
			items: [],
			filter: {
				house: this.$route.query.house || "",
				count_apartments: this.$route.query.count_apartments || "",
				created_at: this.$route.query.created_at || "",
				created_at_first_search:
					this.$route.query.created_at_first_search || "",
				created_at_second_search:
					this.$route.query.created_at_second_search || "",
				id: this.$route.query.id || "",
				house: this.$route.query.house || "",
				publish: this.$route.query.publish || "",
				questions: this.$route.query.questions || "",
				time_vote: this.$route.query.time_vote || "",
			},
			filtersQuery: {
				"house": "house",
				"count_apartments": "count_apartments",
				"created_at": "created_at",
				"created_at_first_search": "created_at_first_search",
				"created_at_second_search": "created_at_second_search",
				"id": "request_id_search",
				"questions": "questions",
				"time_vote": "time_vote",
				"publish": "publish",
				"street_search": "street_search",
			},
			headers: [
				{
					text: "№ ID",
					value: "id",
					width: "120px",
					sortable: false,
				},
				{
					text: "Адрес",
					value: "house",
					width: "300px",
					sortable: false,
				},
				{
					text: "Всего помещений",
					value: "count_apartments",
					width: "150px",
					sortable: false,
				},
				{
					text: "Опубликовано",
					value: "publish",
					width: "150px",
					sortable: false,
				},
				{
					text: "Темы голосования",
					value: "questions",
					width: "250px",
					sortable: false,
				},
				{
					text: "Дата создания",
					value: "created_at",
					width: "200px",
					sortable: false,
				},
				{
					text: "Дата завершения",
					value: "time_vote",
					width: "180px",
					sortable: false,
				},
			],
			options: {
				page: +this.$route.query.page || 1,
				itemsPerPage: 10,
				pageStart: 1,
				pageCount: 0,
				itemsLength: 0,
			},
			isExportingExcel: false,
			loading: true,
			showModalCreate: false,
		};
	},
	computed: {
		...mapGetters({
			catalog: "catalog/GET_CATALOG",
			houses: "ksk/GET_HOUSES",
		}),
	},
	methods: {
		timestampToDdmmyyyy,

		countApartments(item) {
			const nonResApts = item.count_non_residential_premises || 0;
			return item.count_apartments + nonResApts;
		},

		getQuestions(item) {
			return item.questions.map(e => e.title).join(", ") || "---";
		},

		// ? Методы таблицы
		handleRowClick(e) {
			this.$router.push({ path: `/vote/${e._id}` });
		},

		async exportExcel() {
			this.isExportingExcel = true;
			const query = this.updateQuery(true);
			console.log(query);
			try {
				const res = await this.$api.ksk.export_requests_list(query);
				const link = document.createElement("a");
				link.href = res;
				link.download = `Заявки ${timestampToDdmmyyyy()}.xlsx`;
				link.click();
			} catch (error) {
				console.log(error);
			} finally {
				this.isExportingExcel = false;
			}
		},

		async resetFilters() {
			for (const key in this.filter) {
				this.filter[key] = "";
			}
			this.$router.replace({ query: null });
			await this.loadData({});
		},

		async loadData(params) {
			this.loading = true;
			return this.$api.ksk
				.load_votes(params)
				.then(res => {
					this.options.page = res.page;
					this.options.pageCount = res.pages;
					this.options.itemsLength = res.pages * 10;
					this.items = res.records;
				})
				.catch(error => {
					throw error;
				})
				.finally(() => {
					this.loading = false;
				});
		},
		async updateQuery(getQuery = false) {
			const query = Object.entries(this.filter).reduce((acc, [key, val]) => {
				if (!val) return acc;
				return { ...acc, [this.filtersQuery[key]]: val };
			}, {});

			if (getQuery === true) {
				return query;
			}

			this.$router.replace({ query: { ...query, page: this.options.page } });
			await this.loadData(query);
		},
		// ? Конец методов таблицы
	},

	async created() {
		this.$store.dispatch("ksk/load_houses");
		this.$store.dispatch("ksk/load_employee");
	},

	async mounted() {
		try {
			const query = { ...this.$route.query };
			await this.loadData(query);
			console.log(this.items);
			this.loading = false;
		} catch (error) {
			console.error(error);
		}
	},
};
</script>

<style lang="scss" scoped>
.vote-table {
	height: 100vh;
}
::v-deep tbody tr:nth-child(1) td {
	height: 58px !important;
}
</style>
