<template>
	<v-dialog
		v-model="dialog"
		persistent
		:max-width="step < 3 ? '900px' : '1200px'"
	>
		<v-card>
			<!-- Header -->
			<v-card-title class="d-flex justify-space-between border-bottom">
				<div class="d-flex">
					<span class="subtitle-1">Создание голосования</span>
				</div>
				<v-btn color="dark" @click="closeModal" icon>
					<v-icon>mdi-close</v-icon>
				</v-btn>
			</v-card-title>

			<!-- Loading bar -->
			<v-progress-linear
				v-if="loading"
				color="primary"
				height="5"
				indeterminate
			></v-progress-linear>

			<!-- Body -->
			<v-card-text v-else>
				<v-container>
					<v-form ref="form">
						<!-- Первый шаг -->
						<v-row v-if="step === 1">
							<v-col cols="12" sm="6" md="4">
								<span class="subtitle-2 font-weight-light">
									Адрес
								</span>
								<v-autocomplete
									v-model="data.house_id"
									class="border-all mt-2"
									solo
									dense
									hide-details
									flat
									item-text="apartment_address"
									item-value="_id"
									:filter="addressFilter"
									:items="houses"
									required
									:rules="rules.required"
									@change="findHouse"
								></v-autocomplete>
							</v-col>
							<!-- Дополнительный телефон -->
							<v-col cols="12" sm="6" md="4">
								<span class="subtitle-2 font-weight-light">
									РКА
								</span>
								<v-text-field
									v-model="data.rca"
									v-mask="'################'"
									:disabled="foundRca"
									class="border-all mt-2"
									solo
									dense
									hide-details="auto"
									flat
									:rules="rules.rca"
								></v-text-field>
							</v-col>
							<!-- Дата завершения -->
							<v-col class="d-flex flex-column" cols="12" sm="6" md="4">
								<span class="subtitle-2 font-weight-light mb-2">
									Дата завершения
								</span>
								<DatePickerPlain
									:date.sync="data.time_vote"
									:allowPastDates="false"
								/>
							</v-col>
							<!-- Ответственные -->
							<v-col>
								<div class="chips-placeholder">
									<span class="subtitle-2 font-weight-light mb-2">
										Ответственные лица
									</span>
									<!-- :rules="rules.responsible_persons" -->
									<v-combobox
										ref="combobox"
										v-model="data.responsible_persons"
										class="text-caption border-all"
										chips
										small-chips
										multiple
										solo
										dense
										hide-details="auto"
										flat
										:allow-overflow="false"
										placeholder="Введите ФИО и нажмите Enter для добавления"
										@update:search-input="handleInput"
									>
										<template
											v-slot:selection="{ attrs, item, select, selected }"
										>
											<v-chip
												v-bind="attrs"
												close
												small
												:input-value="selected"
												@click="select"
												@click:close="removeChip(item)"
											>
												{{ item }}
											</v-chip>
										</template>
									</v-combobox>
								</div>
							</v-col>
							<!-- Alert -->
							<v-col cols="12" class="py-0">
								<v-alert
									v-if="alert"
									class="mb-0"
									:type="alert.color"
									:color="alert.color"
									outlined
									dense
								>
									{{ alert.message }}
								</v-alert>
							</v-col>
						</v-row>

						<!-- Второй шаг -->
						<VoteTableModalSecond v-else-if="step === 2" ref="secondStep" />

						<!-- Третий шаг -->
						<VoteTableModalThird
							v-else
							ref="thirdStep"
							:house-id="data.house_id"
							:fromModal="true"
						/>
					</v-form>
				</v-container>
			</v-card-text>
			<v-card-actions class="pt-0 pb-5 d-flex justify-end">
				<v-btn
					v-if="step === 2"
					class="subtitle-2 text-capitalize"
					color="grey lighten-2"
					depressed
					:loading="loading"
					@click="step--"
				>
					Назад
				</v-btn>

				<v-btn
					class="subtitle-2 text-capitalize"
					color="primary"
					depressed
					:loading="loading"
					@click="nextStep"
				>
					{{ step === 1 ? "Далее" : step === 2 ? "Создать" : "Сохранить" }}
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import { mapGetters } from "vuex";

import DatePickerPlain from "@/components/app/DatePickerPlain";
import VoteTableModalSecond from "@/components/VoteTableModalSecond";
import VoteTableModalThird from "@/components/VoteTableModalThird";

export default {
	name: "VoteTableModal",
	components: { DatePickerPlain, VoteTableModalSecond, VoteTableModalThird },
	data() {
		return {
			alert: null,
			item: null,
			disabledAddress: false,
			data: {
				rca: '',
				house_id: null,
				time_vote: "",
				responsible_persons: [],
			},
			vote_id: null,
			foundRca: false,
			step: 1,
			dialog: true,
			isAdding: false,
			loading: false,
			searchInput: null,
			rules: {
				required: [v => !!v || ""],
				rca: [v => !!v || "", v => (v && v.length === 16) || "Введите 16 цифр"],
				responsible_persons: [v => !!v.length || ""],
			},
		};
	},
	computed: {
		...mapGetters({
			catalog: "catalog/GET_CATALOG",
			houses: "ksk/GET_HOUSES",
		}),
	},
	watch: {
		alert(val) {
			if (!val) return;
			setTimeout(() => {
				this.alert = null;
			}, 3000);
		},
		'data.rca' () {
			console.log('rca length 16 - ', this.data.rca)
			console.log('rca this.foundRca 16 - ', this.foundRca)
			if(this.data.rca.length == 16 && this.data.house_id) {
				console.log('rca length 16 - ', this.data.rca)
				this.data.ksk_id = this.ksk_id
			}
		}
	},
	methods: {
		async editHouse() {
			this.alert = null;
			let params = {}
			params._method = 'patch'
			params.rca = this.data.rca
			try {
				await this.$api.ksk.edit_house(this.data.house_id, params);

				this.$refs.form.resetValidation();
			} catch (error) {
				this.alert = {
					color: "error",
					message: error.message || "Произошла ошибка. Повторите еще раз.",
				};
			} finally {
				this.isAddingHouse = false;
			}
		},
		async checkRca() {
			this.loading = true;
			this.disabledAddress = false
			try {
				const res = await this.$api.house.check_house_rca(this.data.rca);
				if(res) {
					this.data.apartment_address = res.name
					this.disabledAddress = true
				}
			} catch (error) {
				console.log('error', error)
				this.$root.snackbar.show({ color: "red", message: error.error.response.data.message });
			} finally {
				this.loading = false;
			}
		},
		handleInput(e) {
			this.searchInput = e;
		},
		// Получение дома по ID
		async findHouse() {
			this.alert = null;
			this.foundRca = false;
			try {
				const res = await this.$api.ksk.load_house_by_id(this.data.house_id);
				this.item = res
				console.log('item', this.item)
				if (res?.rca) {
					this.data.rca = res.rca;
					this.foundRca = true;
				} else {
					console.log('not found')
					this.data.rca = ''
				}
			} catch (error) {
				this.alert = {
					color: "error",
					message: "Произошла ошибка",
				};
				console.error(error);
			}
		},
		// Переход к следующему шагу
		async nextStep() {
			if (this.step === 1 && this.customValidation()) {
				if (this.searchInput && !this.data.responsible_persons.length) {
					this.data.responsible_persons.push(this.searchInput);
				}
				await this.editHouse();
				this.step++;
			} else if (this.step === 2) {
				try {
					if (!this.$refs.secondStep.checkValidation()) {
						return;
					}
					
					if (!this.vote_id) {
						await this.createVote();
					}

					await this.$refs.secondStep.postQuestions(this.vote_id);

					this.step++;

					this.alert = {
						color: "success",
						message: "Вопросы успешно добавлены",
					};
				} catch (error) {
					this.alert = {
						color: "danger",
						message: error.message || "Произошла ошибка",
					};
					console.error(error);
				}
			} else if (this.step === 3) {
				this.closeModal();
				this.$emit("update-info");
				return;
			}
		},
		// Удаление ответственного
		removeChip(item) {
			const index = this.data.responsible_persons.indexOf(item);
			this.data.responsible_persons.splice(index, 1);
		},
		// Валидация
		customValidation() {
			let message;
			const valid = this.$refs.form.validate();
			console.log(valid);
			if (!valid) {
				this.alert = {
					color: "error",
					message: "Заполните все выделенные поля",
				};
				return false;
			} else if (this.step === 1 && !this.data.time_vote) {
				message = "Укажите дату завершения";
			}

			if (message) {
				this.alert = {
					color: "error",
					message,
				};
			}
			return !message;
		},
		
		async createVote() {
			this.alert = null;
			const [year, month, day] = this.data.time_vote.split("-");
			const outputDate = `${day}-${month}-${year} 00:00`;

			this.data.vote_date_end = outputDate
			try {
				const formData = this.data.toFormData();
				const res = await this.$api.ksk.add_vote(formData);
				this.vote_id = res.vote._id;
				this.alert = {
					color: "error",
					message: res.success || "Голосование создано",
				};
				return res;
			} catch (error) {
				this.$root.snackbar.show({ color: "red", message: error.message });
				this.alert = {
					color: "error",
					message: error.message || "Произошла ошибка. Повторите еще раз.",
				};
			} finally {
				this.loading = false;
			}
		},

		closeModal() {
			this.dialog = false;
			this.$emit("close-modal");
		},
	},
	async created() {},
};
</script>

<style>
.chips-placeholder .v-autocomplete:not(.v-input--is-focused).v-select--chips input {
	max-height: 25px !important;
}
</style>
